export const annual_reps=[
    {

        'id':"1",
        "head":"2022-2023",
        "house":"right",
        "pdf":"/AnnualReport_22-23.pdf"

    },
    {
        'id':"2",
        "head":"2021-2022",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1nXEUZ2sfntKWDpSYQmKMCGvLZMb2tsMo/view?usp=drive_link"
    },
    {
        'id':"3",
        "head":"2020-2021",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1rJDSv2XryHNC2G_gksNwurYgRY8h1bDS/view?usp=drive_link"
    },
    {
        'id':"4",
        "head":"2016-2017",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1TglO5pntw1Vu5dQZBkIRJ3vOVbjn1ow8/view?usp=drive_link"
    },
    {
        'id':"5",
        "head":"2015-2016",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1kSE0Kn78iyT2cqGL9OIstZPJ_7JPKhe2/view?usp=drive_link"
    },
    {
        'id':"6",
        "head":"2014-2015",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1nk0hhnzn3se_uyZjZnMq1x9_EVGdXjGh/view?usp=drive_link"
    },
    {
        'id':"7",
        "head":"2013-2014",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1kFeqpk40oZ9iTJgnnDE2EGBuiYqD4kIs/view?usp=drive_link"
    },
]